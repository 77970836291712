.img {
  width: 60%;
}
.heading {
  font-size: 30px;
  font-weight: 500;
  color: #db2c2c;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  text-align: left;
  font-weight: 500;
}
.subHeading {
  font-size: 40px;
  font-family: "Nunito Sans", sans-serif;
  font-weight: bold;
  text-align: left;
  margin-bottom: 20px;
}
.content {
  font-size: 15px;
  font-family: "Poppins";
  text-align: left;
  color: white;
  text-align: start;
  margin-bottom: 20px;
}
.btn {
  width: 190px;
  height: 60px;
  font-size: 14px;
  background-color: #d13434 !important;
}
.text {
  margin-left: 20px;
  margin-top: 50px;
  color: white;
}

@media screen and (max-width: 1200px) {
  .text {
    margin-top: 20px;
    margin-right: 50px;
  }
}

@media screen and (max-width: 1100px) {
  .heading {
    font-size: 25px;
  }
  .subHeading {
    font-size: 28px;
    width: 300px;
  }
  .btn {
    height: 50px;
    width: 200px;
  }
}
@media screen and (max-width: 950px) {
  .imgDiv {
    margin-top: 100px;
  }
  .text {
    margin-right: 20px;
  }
}

@media screen and (max-width: 767px) {
  .imgDiv {
    margin-top: 0px;
  }
  .heading {
    font-size: 20px;
  }
  .subHeading {
    font-size: 22px;
    margin-bottom: 22px;
  }
}
@media screen and (max-width: 500px) {
  .heading {
    font-size: 18px;
  }
  .subHeading {
    font-size: 20px;
    margin-bottom: 20px;
    width: 70%;
  }
  .content {
    margin-bottom: 23px;
  }
}

@media screen and (max-width: 355px) {
  .subHeading {
    width: 100%;
  }
}
