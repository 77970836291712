@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&family=Reem+Kufi+Fun&display=swap");

.img {
  width: 100%;
  /* height: 80vh !important; */
}
.heading {
  font-size: 30px;
  font-weight: 500;
  color: #694b9c;
  font-family: "Nunito Sans", sans-serif;
  text-align: left;
  font-weight: 500;
}
.subHeading {
  font-size: 48px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-weight: bold;
  text-align: left;
  width: 400px;
  margin-bottom: 20px;
}
.content {
  font-size: 15px;
  font-family: "Poppins";
  text-align: left;
  color: #222a41;
  width: 80%;
  text-align: justify;
  margin-bottom: 2rem;
  /* margin-bottom: 40px; */
}
.btn {
  width: 180px;
  height: 50px;
  font-size: 14px;
  background-color: #694b9c !important;
}
.text {
  margin-left: 20px;
  margin-top: 20px;
}

@media screen and (max-width: 1200px) {
  .text {
    margin-top: 20px;
  }
}

@media screen and (max-width: 1100px) {
  .heading {
    font-size: 25px;
  }
  .subHeading {
    font-size: 28px;
    width: 300px;
  }
  .btn {
    height: 50px;
    width: 200px;
  }
}
@media screen and (max-width: 950px) {
  .imgDiv {
    margin-top: 100px;
  }
}

@media screen and (max-width: 767px) {
  .imgDiv {
    margin-top: 0px;
  }
  .heading {
    font-size: 20px;
  }
  .subHeading {
    font-size: 22px;
    margin-bottom: 22px;
  }
}
@media screen and (max-width: 500px) {
  .heading {
    font-size: 18px;
  }
  .subHeading {
    font-size: 34px;
    margin-bottom: 20px;
    width: 70%;
  }
  .content {
    width: 95%;

    margin-bottom: 23px;
  }
}

@media screen and (max-width: 355px) {
  .subHeading {
    width: 100%;
  }
}
