.contain{
    margin-top: 68px;
    margin-bottom: 68px;
}
.heading{
    font-family: 'Poppins';
    font-size: 40px;
    color: #222a41;
    font-weight: bold;
    /* margin-bottom: 76px; */
    width: 400px;
}
.head{
    font-family: 'Poppins';
    font-size: 20px;
    color: #222a41;
    font-weight: bold;
    margin-top: 76px;
}
.content{
    font-family: 'Poppins';
    font-size: 20px;
    color: #232323;
    opacity: 0.6;
    margin-bottom: 5px;
}
.email{
    font-family: 'Poppins';
    font-size: 30px;
    color: #222a41;
    font-weight: bold;
    margin-top: 43px;
    margin-bottom: 43px;
}
.label{
    font-family: 'Poppins';
    font-size: 16px;
    color: #232323;
    margin-bottom: 9px;
    margin-left: 10px;
}
.msg{
    height: 183px !important; 
    padding-top: 15px;
}
.input{
    width: 100%;
    background-color: #f7f7f7;
    border-radius: 6px;
    border: solid 1px #e8e8e8;
    height: 60px;
    margin: 9px 0px 20px;
    padding-left: 15px;
}
.input:focus{
    outline: none;
    border: solid 1px #afacac;
}
.btn{
    font-size: 16px;
    height: 60px;
    width: 160px;
    border-radius: 5px !important;
    margin-top: 37px;
}

@media screen and (max-width: 1200px) {
    .heading{
        font-size: 30px;
    }
    .head{
        font-size: 18px;
        margin-top: 50px;
    }
    .content{
        font-size: 18px;
    }
    .email{
        font-size: 25px;
    }
}

@media screen and (max-width: 768px){
    .heading{
        width: 100%;
    }
    .head{
        margin-top: 30px;
    }
}

@media screen and (max-width: 600px){
    .heading{
        font-size: 23px;
    }
    .head{
        margin-top: 25px;
        font-size: 16px;
    }
    .content{
        font-size: 16px;
    }
    .label{
        font-size: 14px;
    }
    .input{
        height: 40px;
    }
}