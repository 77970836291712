.nav_text {
  font-size: 14px !important;
  font-family: "Poppins";
  color: #ffffff !important;
  width: 120px;
}
.nav_text:hover {
  padding-top: 6px;
  color: rgb(255, 217, 1) !important;
  font-weight: bold;
}
.active {
  padding-top: 6px !important;
  font-size: 14px;
  color: rgb(255, 217, 1) !important;
  font-weight: bold;
}

.drop {
  width: 120px;
}

.drop:hover .nav_text {
  color: #694b9c !important;
  font-weight: bold;
  cursor: pointer;
  font-size: 18px;
  /* padding-top: 7px; */
}

.dropdownText {
  text-decoration: none;
  color: #111111;
}

.dropdownItem:hover .dropdownText {
  color: #694b9c;
}
.drop a:hover {
  background-color: white;
}

@media screen and (max-width: 991px) {
  .dropdownItem {
    text-align: center !important;
  }
  .nav_text {
    width: auto;
    padding-top: 0px;
  }
  .drop {
    width: auto;
  }
  .logo {
    font-size: 35px !important;
  }
}

@media screen and (max-width: 600px) {
  .logo {
    font-size: 25px !important;
  }
}
