@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&family=Reem+Kufi+Fun&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bitter:wght@200&family=Jost:ital,wght@0,200;0,300;0,400;0,600;1,400&display=swap");

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.logoimgmob {
  height: 70px !important;
}
@media screen and (min-width: 991px) {
  .logoimgmob {
    display: none !important;
  }
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.custom_btn {
  background-color: #694b9c !important;
  border-radius: 0px !important;
  color: white !important;
}
.custom_btn:hover {
  background-color: #694b9c !important;
}

.dropdown-toggle::after {
  display: none !important;
}
.navbar-toggler-icon {
  background-image: url("./assets/pngkey.com-mobile-menu-icon-png-3321462.png") !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@import url("https://fonts.googleapis.com/css2?family=Montserrat&family=Poppins&display=swap");

body,
html {
  font-family: "Poppins", sans-serif;
}
.Container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@keyframes glowAnimation {
  0% {
    box-shadow: 0 0 3px 1px rgba(25, 25, 187, 0.8);
  }
  50% {
    box-shadow: 0 0 10px 2px rgba(25, 25, 187, 0.8);
  }
  100% {
    box-shadow: 0 0 3px 1px rgba(25, 25, 187, 0.8);
  }
}
/* Styles for the logo */

.logoimg img {
  height: 50px !important;
}
.logoimgmob {
  border-radius: 2.9rem;
}

/* Styles for the navbar links */
/* .Navbar.Collapse {
  display: flex !important;
  justify-content: center !important;
}
.navbar .container {
  transition: 0.1s;
} */

/* Media query for smaller screens */
@media screen and (max-width: 991px) {
  .Container {
    flex-direction: column !important; /* Stack items vertically on smaller screens */
  }

  .logo {
    margin-bottom: 10px !important; /* Add some space between the logo and the links */
  }
}

@media screen and (min-width: 991px) {
  .navbar .container {
    justify-content: center !important;
  }
}

@media screen and (max-width: 991px) {
  .swiper {
    margin-top: 0px;
  }
}
.herotext {
  text-align: start;
}
@media screen and (max-width: 766px) {
  .herotext h3 {
    text-align: center;
  }
}
.herotext h3 {
  font-family: "Nunito Sans", sans-serif !important;
}
.btn-hero {
  margin-top: 1rem;
  width: 150px;
  border-radius: 50px !important;
  padding: 0.9rem !important;
  background-color: #5b3e8d !important;
}

.swiper img {
  filter: brightness(70%);
}
@media (max-width: 767px) {
  .owl-carousel-item img {
    height: 600px !important;
    max-width: 800px;
    overflow: hidden !important;
    object-fit: contains;
    max-inline-size: 100%;
    block-size: auto;
    aspect-ratio: 2/1;
    object-fit: cover;
    object-position: top center;
  }
  .herotext {
    justify-content: center !important;
    text-align: center;
  }
}
.abouttext {
  background-color: white;
  text-align: center !important;
  margin-top: 1.5rem;
  z-index: 999;
}
icon {
  font-size: 70px;
}
.highlights {
  background-color: #e3ffe9 !important;
}
.tournament img {
  border-radius: 10px;
}
.tournament {
  padding-top: 4rem;
}
@media screen and (max-width: 500px) {
  .tournament {
    padding-top: 2rem;
  }
}
.element-price-list-separator {
  display: flex;
  flex-grow: 1;
  margin-left: 10px;
  margin-right: 10px;
  border-bottom-style: dotted;
  border-bottom-width: 2px;
  height: 0;
  margin-top: 10px;
}
.element-price-list-price {
  border-bottom-width: 2px;
}
@media screen and (min-width: 500px) {
  .callus {
    display: none !important;
    background-color: #d13434 !important;
    width: 50% !important;
  }
}
.callus {
  margin-left: 25% !important;
}
.Menucard {
  background: linear-gradient(to top, #7718ca, #b37be5) !important;
  padding-bottom: 1px;
}
.Menucard img {
  width: auto;
  height: 50vh;
}

@media screen and (max-width: 500px) {
  .Menucard img {
    height: 30vh;
  }
}
.smallcard {
  background-color: white !important;
  border-radius: 10px;
  padding: 2rem;
  margin: 1rem;
}
.smallcard span {
  font-size: 40px;
}
.smallcard p {
  color: #cf9e1e;
  /* padding-top: 10px; */
  font-family: "Poppins", sans-serif;
}
.smallcard h6 {
  font-weight: bold;
  font-family: "Poppins", sans-serif;
}
.Footer {
  text-align: center !important;
}

.about-img img {
  transition: 0.5s;
}

.about-img img:hover {
  background: #faea09 !important;
}

/* FOoter */
.footer-1 {
  background-color: rgb(91, 62, 141);
}
.footer-1 h3 {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
}
.footer .btn.btn-social {
  margin-right: 5px;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 45px;
  transition: 0.3s;
}

.footer .btn.btn-social:hover {
  border-color: var(--primary);
  background: var(--primary);
}

.footer .btn.btn-link {
  display: block;
  margin-bottom: 5px;
  padding: 0;
  text-align: left;
  font-size: 16px;
  font-weight: normal;
  text-transform: capitalize;
  transition: 0.3s;
}

.footer .btn.btn-link::before {
  position: relative;
  content: "\f105";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin-right: 10px;
}

.footer .btn.btn-link:hover {
  color: var(--primary) !important;
  letter-spacing: 1px;
  box-shadow: none;
}

.footer .form-control {
  border-color: rgba(255, 255, 255, 0.5);
}

.footer .copyright {
  padding: 25px 0;
  font-size: 15px;
  border-top: 1px solid rgba(256, 256, 256, 0.1);
}

.footer .copyright a {
  color: #ffffff;
}

.footer .footer-menu a {
  margin-right: 15px;
  padding-right: 15px;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
}

.footer .copyright a:hover,
.footer .footer-menu a:hover {
  color: var(--primary) !important;
}

.footer .footer-menu a:last-child {
  margin-right: 0;
  padding-right: 0;
  border-right: none;
}

.gold {
  color: rgb(255, 217, 1);
}
.classes-item {
  transition: 0.5s;
}

.classes-item:hover {
  margin-top: -10px;
}
/* Testimonialss */

team-item .team-text {
  position: absolute;
  width: 250px;
  height: 250px;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #ffffff;
  border: 17px solid var(--light);
  border-radius: 250px;
  transition: 0.5s;
}

.team-item:hover .team-text {
  border-color: var(--primary);
}

/*** Testimonial ***/
.testimonial-carousel {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

@media (min-width: 576px) {
  .testimonial-carousel {
    padding-left: 4rem;
    padding-right: 4rem;
  }
}

.testimonial-carousel .testimonial-item .border {
  border: 1px dashed rgba(0, 185, 142, 0.3) !important;
}

.testimonial-carousel .owl-nav {
  position: absolute;
  width: 100%;
  height: 45px;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  display: flex;
  justify-content: space-between;
  z-index: 1;
}

.testimonial-carousel .owl-nav .owl-prev,
.testimonial-carousel .owl-nav .owl-next {
  position: relative;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  background: var(--primary);
  border-radius: 45px;
  font-size: 20px;
  transition: 0.5s;
}

.testimonial-carousel .owl-nav .owl-prev:hover,
.testimonial-carousel .owl-nav .owl-next:hover {
  background: var(--dark);
}

@media screen and (max-width: 437px) {
  .testimonial-item p {
    font-size: 14px !important;
  }
  .testimonial-item h3 {
    font-size: 16px !important;
  }
  .testimonial-item span {
    font-size: 11px !important;
  }
}

.clients h1 {
  font-family: "Nunito Sans", sans-serif;
}
.clients p {
  font-family: "Poppins";
}
.clients h3 {
  color: #5b3e8d;
}
.purple {
  color: rgb(91, 62, 141);
}
/* gallery */
#filter-buttons button {
  border-radius: 3px;
  background: #fff;
  border-color: transparent;
}

#filter-buttons button:hover {
  background: #ddd;
}

#filter-buttons button.active {
  color: #fff;
  background: rgb(91, 62, 141);
}

#filterable-cards .card {
  width: 15rem;
  border: 2px solid transparent;
}

#filterable-cards .card.hide {
  display: none;
}

@media (max-width: 600px) {
  #filterable-cards {
    justify-content: center;
  }

  #filterable-cards .card {
    width: calc(100% / 2 - 10px);
  }
}
/* contact */
.btn-send {
  background-color: #5b3e8d !important;
  color: white !important;
}

.icon {
  font-size: 35px;
  color: #5b3e8d;
}

@media screen and (max-width: 575px) {
  .col-sm-6 {
    width: 49% !important;
  }
}
.neuro-content {
  font-family: "Poppins", sans-serif;
}
.skills p {
  font-family: "Poppins", sans-serif;
}
.footer-form input::placeholder {
  color: white !important;
}
.footer-form textarea::placeholder {
  color: white !important;
}
/* clients review */

.small-card-swipe {
  padding-top: 5rem;
}
.small-card-image {
  border-radius: 5px;
  overflow: hidden;
}
.small-card-image img {
  max-width: 50% !important;
  border-radius: 50%;
  object-fit: cover;
  position: relative;
}
.small-card-text {
  margin-top: 8px;
  text-align: center;
  color: #ffffff !important;
}
.small-card-text p {
  font-size: 18px !important;
}
.small-card-text p {
  font-size: 11px;
}
.small-card-text h6 {
  font-size: 19px;
  margin-bottom: 2px;
  font-family: "Jost", sans-serif;
  text-align: center;
}

.swipper-wrapper h6 {
  text-align: center;
}

.swiper-wrapper {
  position: relative;
  width: 100% !important;
}
.btn-swiper {
  height: 100px;
  display: block !important;
}
.swiper-slide {
  background-color: #8d5fe2;
  border-radius: 10px;
}

.left-client {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: "Jost", sans-serif;
  padding: 4rem !important;
  padding-right: 4rem;
  background-color: rgb(241, 241, 241);
}
.left-client p {
  color: rgb(137, 135, 135);
}
.left-client h2 {
  font-weight: 700;
}
.swiper-pagination-bullet-active {
  background: white !important;
}
.swiper-button-prev,
.swiper-button-next {
  color: white !important;
}
@media screen and (min-width: 575px) {
  .card-sliderbg {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;

    margin-top: 3rem !important;
  }
}
