.img1 {
  width: 60%;
}
.img2 {
  width: 100%;
  height: auto;
  border-radius: 10px;
}
.topContain {
  padding-left: 120px;
  padding-right: 50px;
  background-color: #f6fafb;
  padding-top: 50px;
  padding-bottom: 20px;
  margin-top: 80px;
}
.headTop {
  font-family: "Poppins";
  font-size: 35px;
  color: #212121;
  font-weight: bolder;
  text-align: left;
  line-height: 1.2;
  margin-bottom: 0px;
}
.identityTop {
  display: block;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-radius: 3px;
  padding: 3px;
  width: 4rem;
  color: goldenrod;
  background-color: goldenrod;
}
.content {
  font-family: "Poppins";
  font-size: 18px;
  color: #111;
  /* width: 100%; */
  text-align: left;
  line-height: 1.3;
}

.contain {
  margin-top: 150px !important;
  margin-bottom: 150px;
}
.heading {
  font-family: "Poppins";
  font-size: 30px;
  font-weight: bold;
  text-align: left;
}
.text {
  font-family: "Poppins";
  font-size: 18px;
  text-align: left;
  color: #444554;
  width: 440px;
}

.heading1 {
  font-family: "Poppins";
  font-size: 30px;
  font-weight: bold;
  text-align: left;
  margin-top: -20px;
}
.text1 {
  font-family: "Poppins";
  font-size: 18px;
  text-align: left;
  color: #444554;
  width: 440px;
}

@media screen and (max-width: 1280px) {
  .topContain {
    margin-top: 40px;
  }
}
@media screen and (max-width: 1200px) {
  .topContain {
    margin-top: 30px;
  }
}
@media screen and (max-width: 1155px) {
  .topContain {
    margin-top: 20px;
  }
}
@media screen and (max-width: 1100px) {
  .topContain {
    padding-left: 90px;
  }
  .headTop {
    font-size: 30px;
  }
  .identityTop {
    font-size: 22px;
  }
  .content {
    font-size: 17px;
  }
}
@media screen and (max-width: 1030px) {
  .img1 {
    margin-top: 10px;
  }
}
@media screen and (max-width: 992px) {
  .topContain {
    padding-left: 70px;
  }

  .text1 {
    width: 100%;
  }
  .img1 {
    margin-top: 20px;
  }
  .img2 {
    padding-top: 60px;
  }
}
@media screen and (max-width: 962px) {
  .img1 {
    margin-top: 40px;
  }
}
@media screen and (max-width: 900px) {
  .img1 {
    margin-top: 40px;
  }
}
@media screen and (max-width: 767px) {
  .topContain {
    margin-top: 0px;
  }
  .img2 {
    padding-top: 0px;
  }
  .text {
    width: 100%;
  }
  .img1 {
    margin-top: -50px !important;
  }
  .contain {
    margin-top: 50px !important;
    margin-bottom: 50px;
  }
  .heading {
    margin-top: 20px;
  }
  .heading1 {
    margin-top: 20px;
  }
  .middleContain {
    padding-left: 50px;
  }
}
@media screen and (max-width: 850px) {
  .topContain {
    padding-left: 50px;
    padding-right: 30px;
  }
  .middleContain {
    padding-left: 50px !important;
  }
  .img1 {
    margin-top: 50px;
  }
}
